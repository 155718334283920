<script>
    export let rendering;
</script>

<div class="d-inline-block m-3 p-3 border border-danger">
    missing componentName for
    <code>
        <pre>
            {JSON.stringify(rendering, null, 2)}
        </pre>
    </code>
</div>



