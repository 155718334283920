import {error} from "@sveltejs/kit";
export const prerender = true;

export async function load({fetch, url}) {
  const route = url.pathname !== "/" ? url.pathname : '/index'
  const pageRequest = await fetch( url.origin + route + '.json');
  const page = await pageRequest.json();

  if (!page.error) {
    return  {
      ...page
    }
  }
  throw error(404, 'Not found');
}
