<script lang="ts">
  import Layout from "@component/Layout.svelte";
  import {browser} from "$app/environment";
  import particles from "$lib/services/particles";
  if (browser) { particles(); }
  export let data;

</script>
<div id="particles-js" class:in-content={data?.subPage}></div>

<Layout route="{data}" />

