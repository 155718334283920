<script>
  import Shield from "@component/patterns/Shield.svelte";

  export let units
</script>
<div class="unit">
{#each units as unit}
  <div class="unit__item">
    <a href="{ unit.url }">
      <div class="unit__img">
        <picture>
          <source srcset="images/{ unit.src }.mobile.webp" type="image/webp" media="(max-width: 360px)">
          <source srcset="images/{ unit.src }.jpg.webp 1x, images/{ unit.src }@2x.jpg.webp 2x, images/{ unit.src }@3x.jpg.webp 3x" type="image/webp">
          <source srcset="images/{ unit.src }.jpg 1x, images/{ unit.src }@2x.jpg 2x, images/{ unit.src }@3x.jpg 3x" type="image/jpeg">
          <img  src="images/{ unit.src }.jpg" width="483" height="322" alt="{ unit.name }">
        </picture>
      </div>
      <div class="unit__headline">
        <ul class="list-unstyled p-0 mb-0">
          <!--{% for keyword in claim %}-->
          <!--<li>{ keyword }</li>-->
          <!--{% endfor %}-->
        </ul>
      </div>
      <Shield isUnit="{true}" claim="{unit.name}"/>
    </a>
  </div>
{/each}
</div>
