<script lang="ts">
    import Rendering from '@component/component-factory.svelte';
    export let name;
    export let route;
</script>

{#if route?.placeholder &&  !!route.placeholder[name]}
  {#each route?.placeholder[name] as rendering}
    <Rendering {rendering}/>
  {/each}
{/if}

