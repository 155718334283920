<script>
  import PhoneIcon from "@component/patterns/PhoneIcon.svelte";
  import EmailIcon from "@component/patterns/EmailIcon.svelte";
</script>

<div class="text-center mb-5 mt-4">
  <a href="mailto:office@practical.at" class="btn btn-outline-primary btn-lg d-flex d-md-inline-flex justify-content-center align-items-center mr-md-2 mb-2 mb-md-0">
    <EmailIcon/>  Schreiben Sie uns
  </a>
    <a href="tel:+4316007350" class="btn btn-outline-primary btn-lg d-flex d-md-inline-flex justify-content-center align-items-center ml-md-2 ">
        <PhoneIcon/> +43 (0) 1 600 73 50
    </a>
</div>

