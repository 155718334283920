<script>
  export let title;
</script>

<div class="py-3 py-lg-5 header">
  <div class="container container--content">
    <h1 class="m-0">
      {title}
    </h1>
  </div>
</div>


<style>
  .header {
    background: var(--primary);
    color: var(--white);
  }
</style>
